import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/Layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import Login from '../components/Login'

import '../scss/page-login.scss'

const loginPage = ({ location }) => {
  const { state: routeState } = location
  const redirect = !routeState ? '/app' : routeState.redirect === 'app' ? '/app' : `/app/${routeState.redirect}`
  return (
    <Layout>
      <Seo title="Login | Talk of the Town" />
      <Hero text="Login" />
      <section className="login-form">
        <Container>
          <Row>
            <Col lg={{ size: 6, offset: 3 }}>
              <div className="wrapperForm">
                <Login redirect={redirect} />
                <div className="formFooter">
                  <Link className="passReset" to="/password-reset/">
                    Esqueceu sua senha?
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
export default loginPage
