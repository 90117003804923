import React, { useState } from 'react'
import { navigate } from 'gatsby'
import useAuth from '../hooks/useAuth'

const Login = ({ redirect }) => {
  const { state, login } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      await login({ email, password })
      navigate(redirect)
    } catch (e) {
      console.log('Error occurred during authentication')
      const { response: { data: { message: [{ messages: [error] }] } } } = e
      const { message: msg } = error
      setError(msg)
    }
  }

  return (
    <div className="w-full max-w-xs">
      <pre>
        {JSON.stringify({ state }, null, 2)}
      </pre>
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="form-row">
          <input
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            value={email}
            className="form-control"
            id="username"
            type="email"
            placeholder="E-mail"
          />
        </div>
        <div className="form-row">
          <input
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            value={password}
            className="form-control"
            id="password"
            type="password"
            placeholder="Senha"
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Sign In
          </button>
        </div>
      </form>
      {(error.length > 1) && (
        <p className="text-center text-red-500 bg-red-200 border p-2">
          {error}
        </p>
      )}
    </div>
  )
}

export default Login
